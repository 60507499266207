ImageLoader = {
    init: function (_0x3a5ex1) {
        var _0x3a5ex2 = {
            limit: 10,
            resize: !0,
            moveSpeed: 100,
            maxWidth: 768,
            maxHeight: 534,
            isJpeg: !1,
            jpegQuality: 0.8,
            jpegBg: '#FFFFFF',
            form: 'imageloaderForm',
            files: 'imageloaderFiles',
            file: 'imageloaderFile',
            image: 'imageloaderImage',
            ghost: 'imageloaderGhost',
            btnBrowse: 'imageloaderBrowse',
            btnClear: 'imageloaderClear',
            btnRotate: 'imageloaderRotate',
            btnRemove: 'imageloaderRemove',
            rotateTitle: 'Rotate',
            removeTitle: 'Remove',
            filter: ['.jpeg', '.jpg', '.png', '.gif', '.bmp', '.ico']
        };
        if (_0x3a5ex1) {
            for (var _0x3a5ex3 in _0x3a5ex2) {
                !(_0x3a5ex3 in _0x3a5ex1) && (_0x3a5ex1[_0x3a5ex3] = _0x3a5ex2[_0x3a5ex3])
            }
        } else {
            _0x3a5ex1 = _0x3a5ex2
        };
        if (window['URL'] || window['webkitURL']) {
            if (document['createElement']('canvas')['getContext']) {
                if (1) {
                    for (var _0x3a5ex4 = document['getElementsByClassName'](_0x3a5ex1['form']), _0x3a5ex5 = 0; _0x3a5ex5 < _0x3a5ex4['length']; _0x3a5ex5++) {
                        ImageLoader['create'](_0x3a5ex1, _0x3a5ex4[_0x3a5ex5])
                    }
                } else {
                    alert("'Demo version of JS Image Loader cannot be used'")
                }
            } else {
                if (void (0) === _0x3a5ex1['onError']) {
                    throw new Error('ImageLoader: browser doesn\'t support canvas')
                };
                _0x3a5ex1['onError']('Browser doesn\'t support canvas')
            }
        } else {
            if (void (0) === _0x3a5ex1['onError']) {
                throw new Error('ImageLoader: browser can\'t load local images')
            };
            _0x3a5ex1['onError']('Browser can\'t load local images')
        }
    },
    create: function (_0x3a5ex6, _0x3a5ex1) {
        if (1) {
            if (0 != _0x3a5ex1['getElementsByClassName'](_0x3a5ex6['files'])['length']) {
                var _0x3a5ex7 = _0x3a5ex1['getElementsByClassName'](_0x3a5ex6['files'])[0];
                if ('div' == _0x3a5ex7['tagName']['toLowerCase']()) {
                    if (0 != _0x3a5ex1['getElementsByClassName'](_0x3a5ex6['btnBrowse'])['length']) {
                        if (_0x3a5ex1['getElementsByClassName'](_0x3a5ex6['btnBrowse'])[0]['onclick'] = function () {
                            $(_0x3a5ex8)['focus']()['click']()
                        }, void (0) !== _0x3a5ex6['btnClear']) {
                            if (0 == _0x3a5ex1['getElementsByClassName'](_0x3a5ex6['btnClear'])['length']) {
                                if (void (0) === _0x3a5ex6['onError']) {
                                    throw new Error('ImageLoader: clear button not found')
                                };
                                return void (_0x3a5ex6['onError']('Clear button not found'))
                            };
                            _0x3a5ex1['getElementsByClassName'](_0x3a5ex6['btnClear'])[0]['onclick'] = function () {
                                for (; _0x3a5ex7['hasChildNodes']();) {
                                    _0x3a5ex7['removeChild'](_0x3a5ex7['firstChild'])
                                }
                            }
                        };
                        _0x3a5ex1['onsubmit'] = function () {
                            return void (0) !== _0x3a5ex6['onSubmit'] && 0 == _0x3a5ex6['onSubmit'](_0x3a5ex1) || ImageLoader['upload'](_0x3a5ex6, _0x3a5ex1), !1
                        };
                        var _0x3a5ex8 = document['createElement']('input');
                        _0x3a5ex8['type'] = 'file', _0x3a5ex8['multiple'] = !0, _0x3a5ex8['accept'] = _0x3a5ex6['filter']['join'](','), _0x3a5ex8['style']['width'] = '0px', _0x3a5ex8['style']['height'] = '0px', _0x3a5ex8['style']['border'] = '0px', _0x3a5ex8['style']['overflow'] = 'hidden', _0x3a5ex8['style']['margin'] = '0px', _0x3a5ex8['style']['padding'] = '0px', _0x3a5ex1['appendChild'](_0x3a5ex8), _0x3a5ex8['onchange'] = function () {
                            var _0x3a5ex1 = window['URL'] || window['webkitURL'];
                            if (_0x3a5ex7['children']['length'] < _0x3a5ex6['limit']) {
                                for (var _0x3a5ex2 = 0; _0x3a5ex2 < _0x3a5ex8['files']['length']; _0x3a5ex2++) {
                                    if (_0x3a5ex8['files'][_0x3a5ex2]['type']['match'](_0x3a5ex6['filter']['join']('|'))) {
                                        var _0x3a5ex3 = _0x3a5ex1['createObjectURL'](_0x3a5ex8['files'][_0x3a5ex2]),
                                            _0x3a5ex4 = document['createElement']('div');
                                        _0x3a5ex4['className'] = _0x3a5ex6['file'], _0x3a5ex4['setAttribute']('angle', 0), _0x3a5ex4['setAttribute']('blob', _0x3a5ex3), _0x3a5ex7['appendChild'](_0x3a5ex4);
                                        var _0x3a5ex5 = document['createElement']('div');
                                        _0x3a5ex5['className'] = _0x3a5ex6['image'], _0x3a5ex5['style']['backgroundImage'] = 'url(' + _0x3a5ex3 + ')', _0x3a5ex4['appendChild'](_0x3a5ex5);
                                        var _0x3a5ex9 = document['createElement']('div');
                                        _0x3a5ex9['className'] = _0x3a5ex6['btnRotate'], _0x3a5ex9['setAttribute']('title', _0x3a5ex6['rotateTitle']), _0x3a5ex4['appendChild'](_0x3a5ex9), _0x3a5ex9['onclick'] = function () {
                                            var _0x3a5ex1;
                                            switch (parseInt(this['parentNode']['getAttribute']('angle'))) {
                                                case 0:
                                                    _0x3a5ex1 = 90;
                                                    break;
                                                case 90:
                                                    _0x3a5ex1 = 180;
                                                    break;
                                                case 180:
                                                    _0x3a5ex1 = 270;
                                                    break;
                                                case 270:
                                                    _0x3a5ex1 = 0
                                            };
                                            this['parentNode']['setAttribute']('angle', _0x3a5ex1);
                                            var _0x3a5ex2 = this['parentNode']['firstChild'];
                                            _0x3a5ex2['style']['webkitTransform'] = 'rotate(' + _0x3a5ex1 + 'deg)', _0x3a5ex2['style']['mozTransform'] = 'rotate(' + _0x3a5ex1 + 'deg)', _0x3a5ex2['style']['msTransform'] = 'rotate(' + _0x3a5ex1 + 'deg)', _0x3a5ex2['style']['oTransform'] = 'rotate(' + _0x3a5ex1 + 'deg)', _0x3a5ex2['style']['transform'] = 'rotate(' + _0x3a5ex1 + 'deg)', ImageLoader['draw'](this['parentNode'], _0x3a5ex6)
                                        };
                                        var _0x3a5exa = document['createElement']('div');
                                        if (_0x3a5exa['className'] = _0x3a5ex6['btnRemove'], _0x3a5exa['setAttribute']('title', _0x3a5ex6['removeTitle']), _0x3a5ex4['appendChild'](_0x3a5exa), _0x3a5exa['onclick'] = function () {
                                            this['parentNode']['parentNode']['removeChild'](this['parentNode'])
                                        }, ImageLoader['draw'](_0x3a5ex4, _0x3a5ex6), _0x3a5ex7['children']['length'] >= _0x3a5ex6['limit']) {
                                            break
                                        }
                                    }
                                }
                            };
                            _0x3a5ex8['value'] = ''
                        }, Sortable['create'](_0x3a5ex7, {
                            animation: _0x3a5ex6['moveSpeed'],
                            ghostClass: _0x3a5ex6['ghost']
                        })
                    } else {
                        if (void (0) === _0x3a5ex6['onError']) {
                            throw new Error('ImageLoader: browse button not found')
                        };
                        _0x3a5ex6['onError']('Browse button not found')
                    }
                } else {
                    if (void (0) === _0x3a5ex6['onError']) {
                        throw new Error('ImageLoader: files container tag name is not a DIV')
                    };
                    _0x3a5ex6['onError']('Files container tag name is not a DIV')
                }
            } else {
                if (void (0) === _0x3a5ex6['onError']) {
                    throw new Error('ImageLoader: files container not found')
                };
                _0x3a5ex6['onError']('Files container not found')
            }
        } else {
            alert('Demo version of JS Image Loader cannot be used')
        }
    },
    draw: function (_0x3a5ex9, _0x3a5exa) {
        var _0x3a5ex6 = document['createElement']('img');
        _0x3a5ex6['src'] = _0x3a5ex9['getAttribute']('blob'), _0x3a5ex6['onload'] = function () {
            var _0x3a5ex1, _0x3a5ex2 = document['createElement']('canvas'),
                _0x3a5ex3 = _0x3a5ex2['getContext']('2d'),
                _0x3a5ex4 = _0x3a5exa['resize'] && (_0x3a5ex6['width'] > _0x3a5exa['maxWidth'] || _0x3a5ex6['height'] > _0x3a5exa['maxHeight']) ? Math['min'](_0x3a5exa['maxWidth'] / _0x3a5ex6['width'], _0x3a5exa['maxHeight'] / _0x3a5ex6['height']) : 1;
            'input' != _0x3a5ex9['lastChild']['tagName']['toLowerCase']() ? ((_0x3a5ex1 = document['createElement']('input'))['setAttribute']('type', 'hidden'), _0x3a5ex1['setAttribute']('name', 'imageloader[]'), _0x3a5ex9['appendChild'](_0x3a5ex1)) : _0x3a5ex1 = _0x3a5ex9['lastChild'];
            var _0x3a5ex5 = parseInt(_0x3a5ex9['getAttribute']('angle'));
            switch (_0x3a5ex5) {
                case 0:
                    _0x3a5ex2['width'] = Math['round'](_0x3a5ex6['width'] * _0x3a5ex4), _0x3a5ex2['height'] = Math['round'](_0x3a5ex6['height'] * _0x3a5ex4), _0x3a5exa['isJpeg'] && (_0x3a5ex3['fillStyle'] = _0x3a5exa['jpegBg'], _0x3a5ex3['fillRect'](0, 0, _0x3a5ex2['width'], _0x3a5ex2['height'])), _0x3a5ex3['drawImage'](_0x3a5ex6, 0, 0, _0x3a5ex2['width'], _0x3a5ex2['height']);
                    break;
                case 90:
                    _0x3a5ex2['width'] = Math['round'](_0x3a5ex6['height'] * _0x3a5ex4), _0x3a5ex2['height'] = Math['round'](_0x3a5ex6['width'] * _0x3a5ex4), _0x3a5exa['isJpeg'] && (_0x3a5ex3['fillStyle'] = _0x3a5exa['jpegBg'], _0x3a5ex3['fillRect'](0, 0, _0x3a5ex2['width'], _0x3a5ex2['height'])), _0x3a5ex3['rotate'](_0x3a5ex5 * Math['PI'] / 180), _0x3a5ex3['drawImage'](_0x3a5ex6, 0, -_0x3a5ex2['width'], _0x3a5ex2['height'], _0x3a5ex2['width']);
                    break;
                case 180:
                    _0x3a5ex2['width'] = Math['round'](_0x3a5ex6['width'] * _0x3a5ex4), _0x3a5ex2['height'] = Math['round'](_0x3a5ex6['height'] * _0x3a5ex4), _0x3a5exa['isJpeg'] && (_0x3a5ex3['fillStyle'] = _0x3a5exa['jpegBg'], _0x3a5ex3['fillRect'](0, 0, _0x3a5ex2['width'], _0x3a5ex2['height'])), _0x3a5ex3['rotate'](_0x3a5ex5 * Math['PI'] / 180), _0x3a5ex3['drawImage'](_0x3a5ex6, -_0x3a5ex2['width'], -_0x3a5ex2['height'], _0x3a5ex2['width'], _0x3a5ex2['height']);
                    break;
                case 270:
                    _0x3a5ex2['width'] = Math['round'](_0x3a5ex6['height'] * _0x3a5ex4), _0x3a5ex2['height'] = Math['round'](_0x3a5ex6['width'] * _0x3a5ex4), _0x3a5exa['isJpeg'] && (_0x3a5ex3['fillStyle'] = _0x3a5exa['jpegBg'], _0x3a5ex3['fillRect'](0, 0, _0x3a5ex2['width'], _0x3a5ex2['height'])), _0x3a5ex3['rotate'](_0x3a5ex5 * Math['PI'] / 180), _0x3a5ex3['drawImage'](_0x3a5ex6, -_0x3a5ex2['height'], 0, _0x3a5ex2['height'], _0x3a5ex2['width'])
            };
            _0x3a5ex1['setAttribute']('value', _0x3a5exa['isJpeg'] ? _0x3a5ex2['toDataURL']('image/jpeg', _0x3a5exa['jpegQuality']) : _0x3a5ex2['toDataURL']()), _0x3a5ex3['clearRect'](0, 0, _0x3a5ex2['width'], _0x3a5ex2['height']), _0x3a5ex2 = _0x3a5ex3 = null, _0x3a5ex6['onload'] = null, _0x3a5ex6 = null
        }
    },
    upload: function (_0x3a5ex4, _0x3a5ex5) {
        setTimeout(function () {
            $('input[type=submit]', _0x3a5ex5)['prop']('disabled', !0)
        }, 10);
        var _0x3a5ex3 = new FormData,
            _0x3a5ex9 = 1,
            _0x3a5exa = _0x3a5ex4['isJpeg'] ? 'jpg' : 'png';
        $('input', _0x3a5ex5)['each'](function (_0x3a5ex1, _0x3a5ex2) {
            $(this)['attr']('name') && ('imageloader' == $(this)['attr']('name')['substr'](0, 11) ? _0x3a5ex3['append']('imageloader' + _0x3a5exa + _0x3a5ex9, ImageLoader.Base64toBlob($(this)['attr']('value'))) : _0x3a5ex3['append']($(this)['attr']('name'), $(this)['attr']('value'))), _0x3a5ex9++
        }), request = $['ajax']({
            type: _0x3a5ex5['method'],
            url: _0x3a5ex5['action'],
            data: _0x3a5ex3,
            cache: !1,
            contentType: !1,
            processData: !1,
            success: function (_0x3a5ex1, _0x3a5ex2, _0x3a5ex3) {
                setTimeout(function () {
                    $('input[type=submit]', _0x3a5ex5)['prop']('disabled', !1)
                }, 10), _0x3a5ex4['onFinish'](_0x3a5ex5, _0x3a5ex1)
            },
            error: function (_0x3a5ex1, _0x3a5ex2, _0x3a5ex3) {
                setTimeout(function () {
                    $('input[type=submit]', _0x3a5ex5)['prop']('disabled', !1)
                }, 10), 'abort' != _0x3a5ex3 && _0x3a5ex4['onError']('Error during uploading the form')
            }
        })
    },
    Base64toBlob: function (_0x3a5ex1) {
        var _0x3a5ex2;
        _0x3a5ex2 = 0 <= _0x3a5ex1['split'](',')[0]['indexOf']('base64') ? atob(_0x3a5ex1['split'](',')[1]) : unescape(_0x3a5ex1['split'](',')[1]);
        for (var _0x3a5ex3 = _0x3a5ex1['split'](',')[0]['split'](':')[1]['split'](';')[0], _0x3a5ex4 = new Uint8Array(_0x3a5ex2['length']), _0x3a5ex5 = 0; _0x3a5ex5 < _0x3a5ex2['length']; _0x3a5ex5++) {
            _0x3a5ex4[_0x3a5ex5] = _0x3a5ex2['charCodeAt'](_0x3a5ex5)
        };
        return new Blob([_0x3a5ex4], {
            type: _0x3a5ex3
        })
    }
},
    function (_0x3a5ex1) {
        'use strict';
        'function' == typeof define && define['amd'] ? define(_0x3a5ex1) : 'undefined' != typeof module && void (0) !== module['exports'] ? module['exports'] = _0x3a5ex1() : 'undefined' != typeof Package ? Sortable = _0x3a5ex1() : window['Sortable'] = _0x3a5ex1()
    }(function () {
        'use strict';
        if ('undefined' == typeof window || void (0) === window['document']) {
            return function () {
                throw new Error('Sortable requires a window with a document')
            }
        };
        var _0x3a5exb, _0x3a5exc, _0x3a5exd, _0x3a5exe, _0x3a5exf, _0x3a5ex10, _0x3a5ex11, _0x3a5ex12, _0x3a5ex13, _0x3a5ex14, _0x3a5ex15, _0x3a5ex8, _0x3a5ex4, _0x3a5ex16, _0x3a5ex7, _0x3a5ex9, _0x3a5ex17, _0x3a5ex1, _0x3a5ex18 = {},
            _0x3a5ex5 = /\s+/g,
            _0x3a5ex19 = 'Sortable' + (new Date)['getTime'](),
            _0x3a5ex1a = window,
            _0x3a5ex1b = _0x3a5ex1a['document'],
            _0x3a5exa = _0x3a5ex1a['parseInt'],
            _0x3a5ex6 = !!('draggable' in _0x3a5ex1b['createElement']('div')),
            _0x3a5ex1c = ((_0x3a5ex1 = _0x3a5ex1b['createElement']('x'))['style']['cssText'] = 'pointer-events:auto', 'auto' === _0x3a5ex1['style']['pointerEvents']),
            _0x3a5ex1d = !1,
            _0x3a5ex1e = Math['abs'],
            _0x3a5ex1f = [],
            _0x3a5ex20 = _0x3a5ex2(function (_0x3a5ex1, _0x3a5ex2, _0x3a5ex3) {
                if (_0x3a5ex3 && _0x3a5ex2['scroll']) {
                    var _0x3a5ex4, _0x3a5ex5, _0x3a5ex9, _0x3a5exa, _0x3a5ex6 = _0x3a5ex2['scrollSensitivity'],
                        _0x3a5ex7 = _0x3a5ex2['scrollSpeed'],
                        _0x3a5ex8 = _0x3a5ex1['clientX'],
                        _0x3a5ex1b = _0x3a5ex1['clientY'],
                        _0x3a5ex1c = window['innerWidth'],
                        _0x3a5ex1f = window['innerHeight'];
                    if (_0x3a5ex12 !== _0x3a5ex3 && (_0x3a5ex11 = _0x3a5ex2['scroll'], _0x3a5ex12 = _0x3a5ex3, !0 === _0x3a5ex11)) {
                        _0x3a5ex11 = _0x3a5ex3;
                        do {
                            if (_0x3a5ex11['offsetWidth'] < _0x3a5ex11['scrollWidth'] || _0x3a5ex11['offsetHeight'] < _0x3a5ex11['scrollHeight']) {
                                break
                            }
                        } while (_0x3a5ex11 = _0x3a5ex11['parentNode']);
                    };
                    _0x3a5ex11 && (_0x3a5ex5 = (_0x3a5ex4 = _0x3a5ex11)['getBoundingClientRect'](), _0x3a5ex9 = (_0x3a5ex1e(_0x3a5ex5['right'] - _0x3a5ex8) <= _0x3a5ex6) - (_0x3a5ex1e(_0x3a5ex5['left'] - _0x3a5ex8) <= _0x3a5ex6), _0x3a5exa = (_0x3a5ex1e(_0x3a5ex5['bottom'] - _0x3a5ex1b) <= _0x3a5ex6) - (_0x3a5ex1e(_0x3a5ex5['top'] - _0x3a5ex1b) <= _0x3a5ex6)), _0x3a5ex9 || _0x3a5exa || (_0x3a5exa = (_0x3a5ex1f - _0x3a5ex1b <= _0x3a5ex6) - (_0x3a5ex1b <= _0x3a5ex6), ((_0x3a5ex9 = (_0x3a5ex1c - _0x3a5ex8 <= _0x3a5ex6) - (_0x3a5ex8 <= _0x3a5ex6)) || _0x3a5exa) && (_0x3a5ex4 = _0x3a5ex1a)), _0x3a5ex18['vx'] === _0x3a5ex9 && _0x3a5ex18['vy'] === _0x3a5exa && _0x3a5ex18['el'] === _0x3a5ex4 || (_0x3a5ex18['el'] = _0x3a5ex4, _0x3a5ex18['vx'] = _0x3a5ex9, _0x3a5ex18['vy'] = _0x3a5exa, clearInterval(_0x3a5ex18['pid']), _0x3a5ex4 && (_0x3a5ex18['pid'] = setInterval(function () {
                        _0x3a5ex4 === _0x3a5ex1a ? _0x3a5ex1a['scrollTo'](_0x3a5ex1a['pageXOffset'] + _0x3a5ex9 * _0x3a5ex7, _0x3a5ex1a['pageYOffset'] + _0x3a5exa * _0x3a5ex7) : (_0x3a5exa && (_0x3a5ex4['scrollTop'] += _0x3a5exa * _0x3a5ex7), _0x3a5ex9 && (_0x3a5ex4['scrollLeft'] += _0x3a5ex9 * _0x3a5ex7))
                    }, 24)))
                }
            }, 30),
            _0x3a5ex21 = function (_0x3a5ex1) {
                var _0x3a5ex2 = _0x3a5ex1['group'];
                _0x3a5ex2 && 'object' == typeof _0x3a5ex2 || (_0x3a5ex2 = _0x3a5ex1['group'] = {
                    name: _0x3a5ex2
                }), ['pull', 'put']['forEach'](function (_0x3a5ex1) {
                    _0x3a5ex1 in _0x3a5ex2 || (_0x3a5ex2[_0x3a5ex1] = !0)
                }), _0x3a5ex1['groups'] = ' ' + _0x3a5ex2['name'] + (_0x3a5ex2['put']['join'] ? ' ' + _0x3a5ex2['put']['join'](' ') : '') + ' '
            };

        function _0x3a5ex22(_0x3a5ex1, _0x3a5ex2) {
            if (!_0x3a5ex1 || !_0x3a5ex1['nodeType'] || 1 !== _0x3a5ex1['nodeType']) {
                throw 'Sortable: `el` must be HTMLElement, and not ' + {}['toString']['call'](_0x3a5ex1)
            };
            this['el'] = _0x3a5ex1, this['options'] = _0x3a5ex2 = _0x3a5ex31({}, _0x3a5ex2), _0x3a5ex1[_0x3a5ex19] = this;
            var _0x3a5ex3 = {
                group: Math['random'](),
                sort: !0,
                disabled: !1,
                store: null,
                handle: null,
                scroll: !0,
                scrollSensitivity: 30,
                scrollSpeed: 10,
                draggable: /[uo]l/i['test'](_0x3a5ex1['nodeName']) ? 'li' : '>*',
                ghostClass: 'sortable-ghost',
                chosenClass: 'sortable-chosen',
                ignore: 'a, img',
                filter: null,
                animation: 0,
                setData: function (_0x3a5ex1, _0x3a5ex2) {
                    _0x3a5ex1['setData']('Text', _0x3a5ex2['textContent'])
                },
                dropBubble: !1,
                dragoverBubble: !1,
                dataIdAttr: 'data-id',
                delay: 0,
                forceFallback: !1,
                fallbackClass: 'sortable-fallback',
                fallbackOnBody: !1
            };
            for (var _0x3a5ex4 in _0x3a5ex3) {
                !(_0x3a5ex4 in _0x3a5ex2) && (_0x3a5ex2[_0x3a5ex4] = _0x3a5ex3[_0x3a5ex4])
            };
            for (var _0x3a5ex5 in _0x3a5ex21(_0x3a5ex2), this) {
                '_' === _0x3a5ex5['charAt'](0) && (this[_0x3a5ex5] = this[_0x3a5ex5]['bind'](this))
            };
            this['nativeDraggable'] = !_0x3a5ex2['forceFallback'] && _0x3a5ex6, _0x3a5ex25(_0x3a5ex1, 'mousedown', this._onTapStart), _0x3a5ex25(_0x3a5ex1, 'touchstart', this._onTapStart), this['nativeDraggable'] && (_0x3a5ex25(_0x3a5ex1, 'dragover', this), _0x3a5ex25(_0x3a5ex1, 'dragenter', this)), _0x3a5ex1f['push'](this._onDragOver), _0x3a5ex2['store'] && this['sort'](_0x3a5ex2['store']['get'](this))
        }

        function _0x3a5ex23(_0x3a5ex1) {
            _0x3a5exe && _0x3a5exe['state'] !== _0x3a5ex1 && (_0x3a5ex28(_0x3a5exe, 'display', _0x3a5ex1 ? 'none' : ''), !_0x3a5ex1 && _0x3a5exe['state'] && _0x3a5exf['insertBefore'](_0x3a5exe, _0x3a5exb), _0x3a5exe['state'] = _0x3a5ex1)
        }

        function _0x3a5ex24(_0x3a5ex1, _0x3a5ex2, _0x3a5ex3) {
            if (_0x3a5ex1) {
                _0x3a5ex3 = _0x3a5ex3 || _0x3a5ex1b;
                do {
                    if ('>*' === _0x3a5ex2 && _0x3a5ex1['parentNode'] === _0x3a5ex3 || _0x3a5ex30(_0x3a5ex1, _0x3a5ex2)) {
                        return _0x3a5ex1
                    }
                } while (_0x3a5ex1 !== _0x3a5ex3 && (_0x3a5ex1 = _0x3a5ex1['parentNode']));
            };
            return null
        }

        function _0x3a5ex25(_0x3a5ex1, _0x3a5ex2, _0x3a5ex3) {
            _0x3a5ex1['addEventListener'](_0x3a5ex2, _0x3a5ex3, !1)
        }

        function _0x3a5ex26(_0x3a5ex1, _0x3a5ex2, _0x3a5ex3) {
            _0x3a5ex1['removeEventListener'](_0x3a5ex2, _0x3a5ex3, !1)
        }

        function _0x3a5ex27(_0x3a5ex1, _0x3a5ex2, _0x3a5ex3) {
            if (_0x3a5ex1) {
                if (_0x3a5ex1['classList']) {
                    _0x3a5ex1['classList'][_0x3a5ex3 ? 'add' : 'remove'](_0x3a5ex2)
                } else {
                    var _0x3a5ex4 = (' ' + _0x3a5ex1['className'] + ' ')['replace'](_0x3a5ex5, ' ')['replace'](' ' + _0x3a5ex2 + ' ', ' ');
                    _0x3a5ex1['className'] = (_0x3a5ex4 + (_0x3a5ex3 ? ' ' + _0x3a5ex2 : ''))['replace'](_0x3a5ex5, ' ')
                }
            }
        }

        function _0x3a5ex28(_0x3a5ex1, _0x3a5ex2, _0x3a5ex3) {
            var _0x3a5ex4 = _0x3a5ex1 && _0x3a5ex1['style'];
            if (_0x3a5ex4) {
                if (void (0) === _0x3a5ex3) {
                    return _0x3a5ex1b['defaultView'] && _0x3a5ex1b['defaultView']['getComputedStyle'] ? _0x3a5ex3 = _0x3a5ex1b['defaultView']['getComputedStyle'](_0x3a5ex1, '') : _0x3a5ex1['currentStyle'] && (_0x3a5ex3 = _0x3a5ex1['currentStyle']), void (0) === _0x3a5ex2 ? _0x3a5ex3 : _0x3a5ex3[_0x3a5ex2]
                };
                _0x3a5ex2 in _0x3a5ex4 || (_0x3a5ex2 = '-webkit-' + _0x3a5ex2), _0x3a5ex4[_0x3a5ex2] = _0x3a5ex3 + ('string' == typeof _0x3a5ex3 ? '' : 'px')
            }
        }

        function _0x3a5ex29(_0x3a5ex1, _0x3a5ex2, _0x3a5ex3) {
            if (_0x3a5ex1) {
                var _0x3a5ex4 = _0x3a5ex1['getElementsByTagName'](_0x3a5ex2),
                    _0x3a5ex5 = 0,
                    _0x3a5ex9 = _0x3a5ex4['length'];
                if (_0x3a5ex3) {
                    for (; _0x3a5ex5 < _0x3a5ex9; _0x3a5ex5++) {
                        _0x3a5ex3(_0x3a5ex4[_0x3a5ex5], _0x3a5ex5)
                    }
                };
                return _0x3a5ex4
            };
            return []
        }

        function _0x3a5ex2a(_0x3a5ex1, _0x3a5ex2, _0x3a5ex3, _0x3a5ex4, _0x3a5ex5, _0x3a5ex9, _0x3a5exa) {
            var _0x3a5ex6 = _0x3a5ex1b['createEvent']('Event'),
                _0x3a5ex7 = (_0x3a5ex1 || _0x3a5ex2[_0x3a5ex19])['options'],
                _0x3a5ex8 = 'on' + _0x3a5ex3['charAt'](0)['toUpperCase']() + _0x3a5ex3['substr'](1);
            _0x3a5ex6['initEvent'](_0x3a5ex3, !0, !0), _0x3a5ex6['to'] = _0x3a5ex2, _0x3a5ex6['from'] = _0x3a5ex5 || _0x3a5ex2, _0x3a5ex6['item'] = _0x3a5ex4 || _0x3a5ex2, _0x3a5ex6['clone'] = _0x3a5exe, _0x3a5ex6['oldIndex'] = _0x3a5ex9, _0x3a5ex6['newIndex'] = _0x3a5exa, _0x3a5ex2['dispatchEvent'](_0x3a5ex6), _0x3a5ex7[_0x3a5ex8] && _0x3a5ex7[_0x3a5ex8]['call'](_0x3a5ex1, _0x3a5ex6)
        }

        function _0x3a5ex2b(_0x3a5ex1, _0x3a5ex2, _0x3a5ex3, _0x3a5ex4, _0x3a5ex5, _0x3a5ex9) {
            var _0x3a5exa, _0x3a5ex6, _0x3a5ex7 = _0x3a5ex1[_0x3a5ex19],
                _0x3a5ex8 = _0x3a5ex7['options']['onMove'];
            return (_0x3a5exa = _0x3a5ex1b['createEvent']('Event'))['initEvent']('move', !0, !0), _0x3a5exa['to'] = _0x3a5ex2, _0x3a5exa['from'] = _0x3a5ex1, _0x3a5exa['dragged'] = _0x3a5ex3, _0x3a5exa['draggedRect'] = _0x3a5ex4, _0x3a5exa['related'] = _0x3a5ex5 || _0x3a5ex2, _0x3a5exa['relatedRect'] = _0x3a5ex9 || _0x3a5ex2['getBoundingClientRect'](), _0x3a5ex1['dispatchEvent'](_0x3a5exa), _0x3a5ex8 && (_0x3a5ex6 = _0x3a5ex8['call'](_0x3a5ex7, _0x3a5exa)), _0x3a5ex6
        }

        function _0x3a5ex2c(_0x3a5ex1) {
            _0x3a5ex1['draggable'] = !1
        }

        function _0x3a5ex2d() {
            _0x3a5ex1d = !1
        }

        function _0x3a5ex2e(_0x3a5ex1) {
            for (var _0x3a5ex2 = _0x3a5ex1['tagName'] + _0x3a5ex1['className'] + _0x3a5ex1['src'] + _0x3a5ex1['href'] + _0x3a5ex1['textContent'], _0x3a5ex3 = _0x3a5ex2['length'], _0x3a5ex4 = 0; _0x3a5ex3--;) {
                _0x3a5ex4 += _0x3a5ex2['charCodeAt'](_0x3a5ex3)
            };
            return _0x3a5ex4.toString(36)
        }

        function _0x3a5ex2f(_0x3a5ex1, _0x3a5ex2) {
            var _0x3a5ex3 = 0;
            if (!_0x3a5ex1 || !_0x3a5ex1['parentNode']) {
                return -1
            };
            for (; _0x3a5ex1 && (_0x3a5ex1 = _0x3a5ex1['previousElementSibling']);) {
                'TEMPLATE' !== _0x3a5ex1['nodeName']['toUpperCase']() && _0x3a5ex30(_0x3a5ex1, _0x3a5ex2) && _0x3a5ex3++
            };
            return _0x3a5ex3
        }

        function _0x3a5ex30(_0x3a5ex1, _0x3a5ex2) {
            if (_0x3a5ex1) {
                var _0x3a5ex3 = (_0x3a5ex2 = _0x3a5ex2['split']('.'))['shift']()['toUpperCase'](),
                    _0x3a5ex4 = new RegExp('\s(' + _0x3a5ex2['join']('|') + ')(?=\s)', 'g');
                return !('' !== _0x3a5ex3 && _0x3a5ex1['nodeName']['toUpperCase']() != _0x3a5ex3 || _0x3a5ex2['length'] && ((' ' + _0x3a5ex1['className'] + ' ')['match'](_0x3a5ex4) || [])['length'] != _0x3a5ex2['length'])
            };
            return !1
        }

        function _0x3a5ex2(_0x3a5ex1, _0x3a5ex2) {
            var _0x3a5ex3, _0x3a5ex4;
            return function () {
                void (0) === _0x3a5ex3 && (_0x3a5ex3 = arguments, _0x3a5ex4 = this, setTimeout(function () {
                    1 === _0x3a5ex3['length'] ? _0x3a5ex1['call'](_0x3a5ex4, _0x3a5ex3[0]) : _0x3a5ex1['apply'](_0x3a5ex4, _0x3a5ex3), _0x3a5ex3 = void (0)
                }, _0x3a5ex2))
            }
        }

        function _0x3a5ex31(_0x3a5ex1, _0x3a5ex2) {
            if (_0x3a5ex1 && _0x3a5ex2) {
                for (var _0x3a5ex3 in _0x3a5ex2) {
                    _0x3a5ex2['hasOwnProperty'](_0x3a5ex3) && (_0x3a5ex1[_0x3a5ex3] = _0x3a5ex2[_0x3a5ex3])
                }
            };
            return _0x3a5ex1
        }
        return _0x3a5ex22['prototype'] = {
            constructor: _0x3a5ex22,
            _onTapStart: function (_0x3a5ex1) {
                var _0x3a5ex2 = this,
                    _0x3a5ex3 = this['el'],
                    _0x3a5ex4 = this['options'],
                    _0x3a5ex5 = _0x3a5ex1['type'],
                    _0x3a5ex9 = _0x3a5ex1['touches'] && _0x3a5ex1['touches'][0],
                    _0x3a5exa = (_0x3a5ex9 || _0x3a5ex1)['target'],
                    _0x3a5ex6 = _0x3a5exa,
                    _0x3a5ex7 = _0x3a5ex4['filter'];
                if (!('mousedown' === _0x3a5ex5 && 0 !== _0x3a5ex1['button'] || _0x3a5ex4['disabled']) && (_0x3a5exa = _0x3a5ex24(_0x3a5exa, _0x3a5ex4['draggable'], _0x3a5ex3))) {
                    if (_0x3a5ex8 = _0x3a5ex2f(_0x3a5exa, _0x3a5ex4['draggable']), 'function' == typeof _0x3a5ex7) {
                        if (_0x3a5ex7['call'](this, _0x3a5ex1, _0x3a5exa, this)) {
                            return _0x3a5ex2a(_0x3a5ex2, _0x3a5ex6, 'filter', _0x3a5exa, _0x3a5ex3, _0x3a5ex8), void (_0x3a5ex1['preventDefault']())
                        }
                    } else {
                        if (_0x3a5ex7 && (_0x3a5ex7 = _0x3a5ex7['split'](',')['some'](function (_0x3a5ex1) {
                            if (_0x3a5ex1 = _0x3a5ex24(_0x3a5ex6, _0x3a5ex1['trim'](), _0x3a5ex3)) {
                                return _0x3a5ex2a(_0x3a5ex2, _0x3a5ex1, 'filter', _0x3a5exa, _0x3a5ex3, _0x3a5ex8), !0
                            }
                        }))) {
                            return void (_0x3a5ex1['preventDefault']())
                        }
                    };
                    _0x3a5ex4['handle'] && !_0x3a5ex24(_0x3a5ex6, _0x3a5ex4['handle'], _0x3a5ex3) || this._prepareDragStart(_0x3a5ex1, _0x3a5ex9, _0x3a5exa)
                }
            },
            _prepareDragStart: function (_0x3a5ex1, _0x3a5ex2, _0x3a5ex3) {
                var _0x3a5ex4, _0x3a5ex5 = this,
                    _0x3a5ex9 = _0x3a5ex5['el'],
                    _0x3a5exa = _0x3a5ex5['options'],
                    _0x3a5ex6 = _0x3a5ex9['ownerDocument'];
                _0x3a5ex3 && !_0x3a5exb && _0x3a5ex3['parentNode'] === _0x3a5ex9 && (_0x3a5ex7 = _0x3a5ex1, _0x3a5exf = _0x3a5ex9, _0x3a5exc = (_0x3a5exb = _0x3a5ex3)['parentNode'], _0x3a5ex10 = _0x3a5exb['nextSibling'], _0x3a5ex16 = _0x3a5exa['group'], _0x3a5ex4 = function () {
                    _0x3a5ex5._disableDelayedDrag(), _0x3a5exb['draggable'] = !0, _0x3a5ex27(_0x3a5exb, _0x3a5ex5['options']['chosenClass'], !0), _0x3a5ex5._triggerDragStart(_0x3a5ex2)
                }, _0x3a5exa['ignore']['split'](',')['forEach'](function (_0x3a5ex1) {
                    _0x3a5ex29(_0x3a5exb, _0x3a5ex1['trim'](), _0x3a5ex2c)
                }), _0x3a5ex25(_0x3a5ex6, 'mouseup', _0x3a5ex5._onDrop), _0x3a5ex25(_0x3a5ex6, 'touchend', _0x3a5ex5._onDrop), _0x3a5ex25(_0x3a5ex6, 'touchcancel', _0x3a5ex5._onDrop), _0x3a5exa['delay'] ? (_0x3a5ex25(_0x3a5ex6, 'mouseup', _0x3a5ex5._disableDelayedDrag), _0x3a5ex25(_0x3a5ex6, 'touchend', _0x3a5ex5._disableDelayedDrag), _0x3a5ex25(_0x3a5ex6, 'touchcancel', _0x3a5ex5._disableDelayedDrag), _0x3a5ex25(_0x3a5ex6, 'mousemove', _0x3a5ex5._disableDelayedDrag), _0x3a5ex25(_0x3a5ex6, 'touchmove', _0x3a5ex5._disableDelayedDrag), _0x3a5ex5['_dragStartTimer'] = setTimeout(_0x3a5ex4, _0x3a5exa['delay'])) : _0x3a5ex4())
            },
            _disableDelayedDrag: function () {
                var _0x3a5ex1 = this['el']['ownerDocument'];
                clearTimeout(this._dragStartTimer), _0x3a5ex26(_0x3a5ex1, 'mouseup', this._disableDelayedDrag), _0x3a5ex26(_0x3a5ex1, 'touchend', this._disableDelayedDrag), _0x3a5ex26(_0x3a5ex1, 'touchcancel', this._disableDelayedDrag), _0x3a5ex26(_0x3a5ex1, 'mousemove', this._disableDelayedDrag), _0x3a5ex26(_0x3a5ex1, 'touchmove', this._disableDelayedDrag)
            },
            _triggerDragStart: function (_0x3a5ex1) {
                _0x3a5ex1 ? (_0x3a5ex7 = {
                    target: _0x3a5exb,
                    clientX: _0x3a5ex1['clientX'],
                    clientY: _0x3a5ex1['clientY']
                }, this._onDragStart(_0x3a5ex7, 'touch')) : this['nativeDraggable'] ? (_0x3a5ex25(_0x3a5exb, 'dragend', this), _0x3a5ex25(_0x3a5exf, 'dragstart', this._onDragStart)) : this._onDragStart(_0x3a5ex7, !0);
                try {
                    _0x3a5ex1b['selection'] ? _0x3a5ex1b['selection']['empty']() : window['getSelection']()['removeAllRanges']()
                } catch (_0x3a5ex1) { }
            },
            _dragStarted: function () {
                _0x3a5exf && _0x3a5exb && (_0x3a5ex27(_0x3a5exb, this['options']['ghostClass'], !0), _0x3a5ex2a(_0x3a5ex22['active'] = this, _0x3a5exf, 'start', _0x3a5exb, _0x3a5exf, _0x3a5ex8))
            },
            _emulateDragOver: function () {
                if (_0x3a5ex9) {
                    if (this['_lastX'] === _0x3a5ex9['clientX'] && this['_lastY'] === _0x3a5ex9['clientY']) {
                        return
                    };
                    this['_lastX'] = _0x3a5ex9['clientX'], this['_lastY'] = _0x3a5ex9['clientY'], _0x3a5ex1c || _0x3a5ex28(_0x3a5exd, 'display', 'none');
                    var _0x3a5ex1 = _0x3a5ex1b['elementFromPoint'](_0x3a5ex9['clientX'], _0x3a5ex9['clientY']),
                        _0x3a5ex2 = _0x3a5ex1,
                        _0x3a5ex3 = ' ' + this['options']['group']['name'],
                        _0x3a5ex4 = _0x3a5ex1f['length'];
                    if (_0x3a5ex2) {
                        do {
                            if (_0x3a5ex2[_0x3a5ex19] && -1 < _0x3a5ex2[_0x3a5ex19]['options']['groups']['indexOf'](_0x3a5ex3)) {
                                for (; _0x3a5ex4--;) {
                                    _0x3a5ex1f[_0x3a5ex4]({
                                        clientX: _0x3a5ex9['clientX'],
                                        clientY: _0x3a5ex9['clientY'],
                                        target: _0x3a5ex1,
                                        rootEl: _0x3a5ex2
                                    })
                                };
                                break
                            };
                            _0x3a5ex1 = _0x3a5ex2
                        } while (_0x3a5ex2 = _0x3a5ex2['parentNode']);
                    };
                    _0x3a5ex1c || _0x3a5ex28(_0x3a5exd, 'display', '')
                }
            },
            _onTouchMove: function (_0x3a5ex1) {
                if (_0x3a5ex7) {
                    _0x3a5ex22['active'] || this._dragStarted(), this._appendGhost();
                    var _0x3a5ex2 = _0x3a5ex1['touches'] ? _0x3a5ex1['touches'][0] : _0x3a5ex1,
                        _0x3a5ex3 = _0x3a5ex2['clientX'] - _0x3a5ex7['clientX'],
                        _0x3a5ex4 = _0x3a5ex2['clientY'] - _0x3a5ex7['clientY'],
                        _0x3a5ex5 = _0x3a5ex1['touches'] ? 'translate3d(' + _0x3a5ex3 + 'px,' + _0x3a5ex4 + 'px,0)' : 'translate(' + _0x3a5ex3 + 'px,' + _0x3a5ex4 + 'px)';
                    _0x3a5ex17 = !0, _0x3a5ex9 = _0x3a5ex2, _0x3a5ex28(_0x3a5exd, 'webkitTransform', _0x3a5ex5), _0x3a5ex28(_0x3a5exd, 'mozTransform', _0x3a5ex5), _0x3a5ex28(_0x3a5exd, 'msTransform', _0x3a5ex5), _0x3a5ex28(_0x3a5exd, 'transform', _0x3a5ex5), _0x3a5ex1['preventDefault']()
                }
            },
            _appendGhost: function () {
                if (!_0x3a5exd) {
                    var _0x3a5ex1, _0x3a5ex2 = _0x3a5exb['getBoundingClientRect'](),
                        _0x3a5ex3 = _0x3a5ex28(_0x3a5exb),
                        _0x3a5ex4 = this['options'];
                    _0x3a5ex27(_0x3a5exd = _0x3a5exb['cloneNode'](!0), _0x3a5ex4['ghostClass'], !1), _0x3a5ex27(_0x3a5exd, _0x3a5ex4['fallbackClass'], !0), _0x3a5ex28(_0x3a5exd, 'top', _0x3a5ex2['top'] - _0x3a5exa(_0x3a5ex3['marginTop'], 10)), _0x3a5ex28(_0x3a5exd, 'left', _0x3a5ex2['left'] - _0x3a5exa(_0x3a5ex3['marginLeft'], 10)), _0x3a5ex28(_0x3a5exd, 'width', _0x3a5ex2['width']), _0x3a5ex28(_0x3a5exd, 'height', _0x3a5ex2['height']), _0x3a5ex28(_0x3a5exd, 'opacity', '0.8'), _0x3a5ex28(_0x3a5exd, 'position', 'fixed'), _0x3a5ex28(_0x3a5exd, 'zIndex', '100000'), _0x3a5ex28(_0x3a5exd, 'pointerEvents', 'none'), _0x3a5ex4['fallbackOnBody'] && _0x3a5ex1b['body']['appendChild'](_0x3a5exd) || _0x3a5exf['appendChild'](_0x3a5exd), _0x3a5ex1 = _0x3a5exd['getBoundingClientRect'](), _0x3a5ex28(_0x3a5exd, 'width', 2 * _0x3a5ex2['width'] - _0x3a5ex1['width']), _0x3a5ex28(_0x3a5exd, 'height', 2 * _0x3a5ex2['height'] - _0x3a5ex1['height'])
                }
            },
            _onDragStart: function (_0x3a5ex1, _0x3a5ex2) {
                var _0x3a5ex3 = _0x3a5ex1['dataTransfer'],
                    _0x3a5ex4 = this['options'];
                this._offUpEvents(), 'clone' == _0x3a5ex16['pull'] && (_0x3a5ex28(_0x3a5exe = _0x3a5exb['cloneNode'](!0), 'display', 'none'), _0x3a5exf['insertBefore'](_0x3a5exe, _0x3a5exb)), _0x3a5ex2 ? ('touch' === _0x3a5ex2 ? (_0x3a5ex25(_0x3a5ex1b, 'touchmove', this._onTouchMove), _0x3a5ex25(_0x3a5ex1b, 'touchend', this._onDrop), _0x3a5ex25(_0x3a5ex1b, 'touchcancel', this._onDrop)) : (_0x3a5ex25(_0x3a5ex1b, 'mousemove', this._onTouchMove), _0x3a5ex25(_0x3a5ex1b, 'mouseup', this._onDrop)), this['_loopId'] = setInterval(this._emulateDragOver, 50)) : (_0x3a5ex3 && (_0x3a5ex3['effectAllowed'] = 'move', _0x3a5ex4['setData'] && _0x3a5ex4['setData']['call'](this, _0x3a5ex3, _0x3a5exb)), _0x3a5ex25(_0x3a5ex1b, 'drop', this), setTimeout(this._dragStarted, 0))
            },
            _onDragOver: function (_0x3a5ex1) {
                var _0x3a5ex2, _0x3a5ex3, _0x3a5ex4, _0x3a5ex5, _0x3a5ex9, _0x3a5exa, _0x3a5ex6 = this['el'],
                    _0x3a5ex7 = this['options'],
                    _0x3a5ex8 = _0x3a5ex7['group'],
                    _0x3a5ex1b = _0x3a5ex8['put'],
                    _0x3a5ex1c = _0x3a5ex16 === _0x3a5ex8,
                    _0x3a5ex1f = _0x3a5ex7['sort'];
                if (void (0) !== _0x3a5ex1['preventDefault'] && (_0x3a5ex1['preventDefault'](), !_0x3a5ex7['dragoverBubble'] && _0x3a5ex1['stopPropagation']()), _0x3a5ex17 = !0, _0x3a5ex16 && !_0x3a5ex7['disabled'] && (_0x3a5ex1c ? _0x3a5ex1f || (_0x3a5ex4 = !_0x3a5exf['contains'](_0x3a5exb)) : _0x3a5ex16['pull'] && _0x3a5ex1b && (_0x3a5ex16['name'] === _0x3a5ex8['name'] || _0x3a5ex1b['indexOf'] && ~_0x3a5ex1b['indexOf'](_0x3a5ex16['name']))) && (void (0) === _0x3a5ex1['rootEl'] || _0x3a5ex1['rootEl'] === this['el'])) {
                    if (_0x3a5ex20(_0x3a5ex1, _0x3a5ex7, this['el']), _0x3a5ex1d) {
                        return
                    };
                    if (_0x3a5ex2 = _0x3a5ex24(_0x3a5ex1['target'], _0x3a5ex7['draggable'], _0x3a5ex6), _0x3a5ex3 = _0x3a5exb['getBoundingClientRect'](), _0x3a5ex4) {
                        return _0x3a5ex23(!0), void ((_0x3a5exe || _0x3a5ex10 ? _0x3a5exf['insertBefore'](_0x3a5exb, _0x3a5exe || _0x3a5ex10) : _0x3a5ex1f || _0x3a5exf['appendChild'](_0x3a5exb)))
                    };
                    if (0 === _0x3a5ex6['children']['length'] || _0x3a5ex6['children'][0] === _0x3a5exd || _0x3a5ex6 === _0x3a5ex1['target'] && (_0x3a5ex5 = _0x3a5ex1, _0x3a5ex9 = _0x3a5ex6['lastElementChild'], _0x3a5exa = _0x3a5ex9['getBoundingClientRect'](), _0x3a5ex2 = (5 < _0x3a5ex5['clientY'] - (_0x3a5exa['top'] + _0x3a5exa['height']) || 5 < _0x3a5ex5['clientX'] - (_0x3a5exa['right'] + _0x3a5exa['width'])) && _0x3a5ex9)) {
                        if (_0x3a5ex2) {
                            if (_0x3a5ex2['animated']) {
                                return
                            };
                            _0x3a5ex12 = _0x3a5ex2['getBoundingClientRect']()
                        };
                        _0x3a5ex23(_0x3a5ex1c), !1 !== _0x3a5ex2b(_0x3a5exf, _0x3a5ex6, _0x3a5exb, _0x3a5ex3, _0x3a5ex2, _0x3a5ex12) && (_0x3a5exb['contains'](_0x3a5ex6) || (_0x3a5ex6['appendChild'](_0x3a5exb), _0x3a5exc = _0x3a5ex6), this._animate(_0x3a5ex3, _0x3a5exb), _0x3a5ex2 && this._animate(_0x3a5ex12, _0x3a5ex2))
                    } else {
                        if (_0x3a5ex2 && !_0x3a5ex2['animated'] && _0x3a5ex2 !== _0x3a5exb && void (0) !== _0x3a5ex2['parentNode'][_0x3a5ex19]) {
                            _0x3a5ex13 !== _0x3a5ex2 && (_0x3a5ex14 = _0x3a5ex28(_0x3a5ex13 = _0x3a5ex2), _0x3a5ex15 = _0x3a5ex28(_0x3a5ex2['parentNode']));
                            var _0x3a5ex11, _0x3a5ex12 = _0x3a5ex2['getBoundingClientRect'](),
                                _0x3a5ex18 = _0x3a5ex12['right'] - _0x3a5ex12['left'],
                                _0x3a5ex1a = _0x3a5ex12['bottom'] - _0x3a5ex12['top'],
                                _0x3a5ex1e = /left|right|inline/['test'](_0x3a5ex14['cssFloat'] + _0x3a5ex14['display']) || 'flex' == _0x3a5ex15['display'] && 0 === _0x3a5ex15['flex-direction']['indexOf']('row'),
                                _0x3a5ex21 = _0x3a5ex2['offsetWidth'] > _0x3a5exb['offsetWidth'],
                                _0x3a5ex22 = _0x3a5ex2['offsetHeight'] > _0x3a5exb['offsetHeight'],
                                _0x3a5ex25 = 0.5 < (_0x3a5ex1e ? (_0x3a5ex1['clientX'] - _0x3a5ex12['left']) / _0x3a5ex18 : (_0x3a5ex1['clientY'] - _0x3a5ex12['top']) / _0x3a5ex1a),
                                _0x3a5ex26 = _0x3a5ex2['nextElementSibling'],
                                _0x3a5ex27 = _0x3a5ex2b(_0x3a5exf, _0x3a5ex6, _0x3a5exb, _0x3a5ex3, _0x3a5ex2, _0x3a5ex12);
                            if (!1 !== _0x3a5ex27) {
                                if (_0x3a5ex1d = !0, setTimeout(_0x3a5ex2d, 30), _0x3a5ex23(_0x3a5ex1c), 1 === _0x3a5ex27 || -1 === _0x3a5ex27) {
                                    _0x3a5ex11 = 1 === _0x3a5ex27
                                } else {
                                    if (_0x3a5ex1e) {
                                        var _0x3a5ex29 = _0x3a5exb['offsetTop'],
                                            _0x3a5ex2a = _0x3a5ex2['offsetTop'];
                                        _0x3a5ex11 = _0x3a5ex29 === _0x3a5ex2a ? _0x3a5ex2['previousElementSibling'] === _0x3a5exb && !_0x3a5ex21 || _0x3a5ex25 && _0x3a5ex21 : _0x3a5ex29 < _0x3a5ex2a
                                    } else {
                                        _0x3a5ex11 = _0x3a5ex26 !== _0x3a5exb && !_0x3a5ex22 || _0x3a5ex25 && _0x3a5ex22
                                    }
                                };
                                _0x3a5exb['contains'](_0x3a5ex6) || (_0x3a5ex11 && !_0x3a5ex26 ? _0x3a5ex6['appendChild'](_0x3a5exb) : _0x3a5ex2['parentNode']['insertBefore'](_0x3a5exb, _0x3a5ex11 ? _0x3a5ex26 : _0x3a5ex2)), _0x3a5exc = _0x3a5exb['parentNode'], this._animate(_0x3a5ex3, _0x3a5exb), this._animate(_0x3a5ex12, _0x3a5ex2)
                            }
                        }
                    }
                }
            },
            _animate: function (_0x3a5ex1, _0x3a5ex2) {
                var _0x3a5ex3 = this['options']['animation'];
                if (_0x3a5ex3) {
                    var _0x3a5ex4 = _0x3a5ex2['getBoundingClientRect']();
                    _0x3a5ex28(_0x3a5ex2, 'transition', 'none'), _0x3a5ex28(_0x3a5ex2, 'transform', 'translate3d(' + (_0x3a5ex1['left'] - _0x3a5ex4['left']) + 'px,' + (_0x3a5ex1['top'] - _0x3a5ex4['top']) + 'px,0)'), _0x3a5ex2['offsetWidth'], _0x3a5ex28(_0x3a5ex2, 'transition', 'all ' + _0x3a5ex3 + 'ms'), _0x3a5ex28(_0x3a5ex2, 'transform', 'translate3d(0,0,0)'), clearTimeout(_0x3a5ex2['animated']), _0x3a5ex2['animated'] = setTimeout(function () {
                        _0x3a5ex28(_0x3a5ex2, 'transition', ''), _0x3a5ex28(_0x3a5ex2, 'transform', ''), _0x3a5ex2['animated'] = !1
                    }, _0x3a5ex3)
                }
            },
            _offUpEvents: function () {
                var _0x3a5ex1 = this['el']['ownerDocument'];
                _0x3a5ex26(_0x3a5ex1b, 'touchmove', this._onTouchMove), _0x3a5ex26(_0x3a5ex1, 'mouseup', this._onDrop), _0x3a5ex26(_0x3a5ex1, 'touchend', this._onDrop), _0x3a5ex26(_0x3a5ex1, 'touchcancel', this._onDrop)
            },
            _onDrop: function (_0x3a5ex1) {
                var _0x3a5ex2 = this['el'],
                    _0x3a5ex3 = this['options'];
                clearInterval(this._loopId), clearInterval(_0x3a5ex18['pid']), clearTimeout(this._dragStartTimer), _0x3a5ex26(_0x3a5ex1b, 'mousemove', this._onTouchMove), this['nativeDraggable'] && (_0x3a5ex26(_0x3a5ex1b, 'drop', this), _0x3a5ex26(_0x3a5ex2, 'dragstart', this._onDragStart)), this._offUpEvents(), _0x3a5ex1 && (_0x3a5ex17 && (_0x3a5ex1['preventDefault'](), !_0x3a5ex3['dropBubble'] && _0x3a5ex1['stopPropagation']()), _0x3a5exd && _0x3a5exd['parentNode']['removeChild'](_0x3a5exd), _0x3a5exb && (this['nativeDraggable'] && _0x3a5ex26(_0x3a5exb, 'dragend', this), _0x3a5ex2c(_0x3a5exb), _0x3a5ex27(_0x3a5exb, this['options']['ghostClass'], !1), _0x3a5ex27(_0x3a5exb, this['options']['chosenClass'], !1), _0x3a5exf !== _0x3a5exc ? 0 <= (_0x3a5ex4 = _0x3a5ex2f(_0x3a5exb, _0x3a5ex3['draggable'])) && (_0x3a5ex2a(null, _0x3a5exc, 'sort', _0x3a5exb, _0x3a5exf, _0x3a5ex8, _0x3a5ex4), _0x3a5ex2a(this, _0x3a5exf, 'sort', _0x3a5exb, _0x3a5exf, _0x3a5ex8, _0x3a5ex4), _0x3a5ex2a(null, _0x3a5exc, 'add', _0x3a5exb, _0x3a5exf, _0x3a5ex8, _0x3a5ex4), _0x3a5ex2a(this, _0x3a5exf, 'remove', _0x3a5exb, _0x3a5exf, _0x3a5ex8, _0x3a5ex4)) : (_0x3a5exe && _0x3a5exe['parentNode']['removeChild'](_0x3a5exe), _0x3a5exb['nextSibling'] !== _0x3a5ex10 && 0 <= (_0x3a5ex4 = _0x3a5ex2f(_0x3a5exb, _0x3a5ex3['draggable'])) && (_0x3a5ex2a(this, _0x3a5exf, 'update', _0x3a5exb, _0x3a5exf, _0x3a5ex8, _0x3a5ex4), _0x3a5ex2a(this, _0x3a5exf, 'sort', _0x3a5exb, _0x3a5exf, _0x3a5ex8, _0x3a5ex4))), _0x3a5ex22['active'] && (null !== _0x3a5ex4 && -1 !== _0x3a5ex4 || (_0x3a5ex4 = _0x3a5ex8), _0x3a5ex2a(this, _0x3a5exf, 'end', _0x3a5exb, _0x3a5exf, _0x3a5ex8, _0x3a5ex4), this['save']()))), this._nulling()
            },
            _nulling: function () {
                _0x3a5exf = _0x3a5exb = _0x3a5exc = _0x3a5exd = _0x3a5ex10 = _0x3a5exe = _0x3a5ex11 = _0x3a5ex12 = _0x3a5ex7 = _0x3a5ex9 = _0x3a5ex17 = _0x3a5ex4 = _0x3a5ex13 = _0x3a5ex14 = _0x3a5ex16 = _0x3a5ex22['active'] = null
            },
            handleEvent: function (_0x3a5ex1) {
                var _0x3a5ex2 = _0x3a5ex1['type'];
                'dragover' === _0x3a5ex2 || 'dragenter' === _0x3a5ex2 ? _0x3a5exb && (this._onDragOver(_0x3a5ex1), function (_0x3a5ex1) {
                    _0x3a5ex1['dataTransfer'] && (_0x3a5ex1['dataTransfer']['dropEffect'] = 'move');
                    _0x3a5ex1['preventDefault']()
                }(_0x3a5ex1)) : 'drop' !== _0x3a5ex2 && 'dragend' !== _0x3a5ex2 || this._onDrop(_0x3a5ex1)
            },
            toArray: function () {
                for (var _0x3a5ex1, _0x3a5ex2 = [], _0x3a5ex3 = this['el']['children'], _0x3a5ex4 = 0, _0x3a5ex5 = _0x3a5ex3['length'], _0x3a5ex9 = this['options']; _0x3a5ex4 < _0x3a5ex5; _0x3a5ex4++) {
                    _0x3a5ex24(_0x3a5ex1 = _0x3a5ex3[_0x3a5ex4], _0x3a5ex9['draggable'], this['el']) && _0x3a5ex2['push'](_0x3a5ex1['getAttribute'](_0x3a5ex9['dataIdAttr']) || _0x3a5ex2e(_0x3a5ex1))
                };
                return _0x3a5ex2
            },
            sort: function (_0x3a5ex1) {
                var _0x3a5ex4 = {},
                    _0x3a5ex5 = this['el'];
                this['toArray']()['forEach'](function (_0x3a5ex1, _0x3a5ex2) {
                    var _0x3a5ex3 = _0x3a5ex5['children'][_0x3a5ex2];
                    _0x3a5ex24(_0x3a5ex3, this['options']['draggable'], _0x3a5ex5) && (_0x3a5ex4[_0x3a5ex1] = _0x3a5ex3)
                }, this), _0x3a5ex1['forEach'](function (_0x3a5ex1) {
                    _0x3a5ex4[_0x3a5ex1] && (_0x3a5ex5['removeChild'](_0x3a5ex4[_0x3a5ex1]), _0x3a5ex5['appendChild'](_0x3a5ex4[_0x3a5ex1]))
                })
            },
            save: function () {
                var _0x3a5ex1 = this['options']['store'];
                _0x3a5ex1 && _0x3a5ex1['set'](this)
            },
            closest: function (_0x3a5ex1, _0x3a5ex2) {
                return _0x3a5ex24(_0x3a5ex1, _0x3a5ex2 || this['options']['draggable'], this['el'])
            },
            option: function (_0x3a5ex1, _0x3a5ex2) {
                var _0x3a5ex3 = this['options'];
                if (void (0) === _0x3a5ex2) {
                    return _0x3a5ex3[_0x3a5ex1]
                };
                _0x3a5ex3[_0x3a5ex1] = _0x3a5ex2, 'group' === _0x3a5ex1 && _0x3a5ex21(_0x3a5ex3)
            },
            destroy: function () {
                var _0x3a5ex1 = this['el'];
                _0x3a5ex1[_0x3a5ex19] = null, _0x3a5ex26(_0x3a5ex1, 'mousedown', this._onTapStart), _0x3a5ex26(_0x3a5ex1, 'touchstart', this._onTapStart), this['nativeDraggable'] && (_0x3a5ex26(_0x3a5ex1, 'dragover', this), _0x3a5ex26(_0x3a5ex1, 'dragenter', this)), Array['prototype']['forEach']['call'](_0x3a5ex1['querySelectorAll']('[draggable]'), function (_0x3a5ex1) {
                    _0x3a5ex1['removeAttribute']('draggable')
                }), _0x3a5ex1f['splice'](_0x3a5ex1f['indexOf'](this._onDragOver), 1), this._onDrop(), this['el'] = _0x3a5ex1 = null
            }
        }, _0x3a5ex22['utils'] = {
            on: _0x3a5ex25,
            off: _0x3a5ex26,
            css: _0x3a5ex28,
            find: _0x3a5ex29,
            is: function (_0x3a5ex1, _0x3a5ex2) {
                return !!_0x3a5ex24(_0x3a5ex1, _0x3a5ex2, _0x3a5ex1)
            },
            extend: _0x3a5ex31,
            throttle: _0x3a5ex2,
            closest: _0x3a5ex24,
            toggleClass: _0x3a5ex27,
            index: _0x3a5ex2f
        }, _0x3a5ex22['create'] = function (_0x3a5ex1, _0x3a5ex2) {
            return new _0x3a5ex22(_0x3a5ex1, _0x3a5ex2)
        }, _0x3a5ex22['version'] = '1.4.2', _0x3a5ex22
    })