$(function () {

    $(".owl-prev").html('<i class="fa fa-chevron-left"></i>');
    $(".owl-next").html('<i class="fa fa-chevron-right"></i>');

    $(document).on("change", '.region', function () {
        var modals = "/json-get-sub-regions";

        $.get(modals,
            { id: $(this).val() },
            function (data) {
                var model = $('.subregion');
                model.empty();
                model.append("<option value=''>Select Sub Region</option>");
                $.each(data, function (name, id) {
                    model.append("<option value='" + id + "'>" + name + "</option>");
                });
            }
        );
    });

    $(document).on("change", '.smake', function () {
        var modals = "/json-get-modals";

        $.get(modals,
            { ids: $(this).val() },
            function (data) {
                var model = $('.smodal');
                model.empty();
                model.append("<option value=''>Select Model</option>");
                $.each(data, function (name, id) {
                    model.append("<option value='" + id + "'>" + name + "</option>");
                });
                model.append("<option value='Other'>Other</option>");
            }
        );
    });

    $(document).on("change", '.min_year', function () {
        var c_year = $(this).val();
        var m_year = new Date().getFullYear();
        var data = '';
        for (var i = m_year; i >= c_year; i--) {
            data += "<option value='" + i + "'>" + i + "</option>";
        }

        var model = $('.max_year');
        model.empty();
        model.append("<option value=''>Max Year</option>");
        model.append(data);

    });

    $(document).on("change", '.min_price', function () {
        var c_price = $(this).val();
        var prices = [0, 1000, 10000, 50000, 100000, 250000, 500000, 750000];
        var data = '';

        prices.forEach(element => {
            if (element > c_price) {
                if (element == 750000) {
                    data += "<option value='750000'>750000+</option>";
                } else {
                    data += "<option value='" + element + "'>" + element + "</option>";
                }
            }
        });

        if (750000 == c_price) {
            data += "<option value='750000'>750000+</option>";
        }


        var model = $('.max_price');
        model.empty();
        model.append("<option value=''>Max Price</option>");
        model.append(data);

    });

    $(document).on("change", '.min_price_part', function () {
        var c_price = $(this).val();
        var prices = [0, 100, 350, 850, 5000];
        var data = '';

        prices.forEach(element => {
            if (element > c_price) {
                if (element == 5000) {
                    data += "<option value='5000'>5000+</option>";
                } else {
                    data += "<option value='" + element + "'>" + element + "</option>";
                }
            }
        });

        if (5000 == c_price) {
            data += "<option value='5000'>5000+</option>";
        }


        var model = $('.max_price');
        model.empty();
        model.append("<option value=''>Max Price</option>");
        model.append(data);

    });

})